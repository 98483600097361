//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable.vue";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    },
    withoutCostLoader: {
      type: Function
    }
  },
  data() {
    return {
      currentEdit: "",
      editId: null
    };
  },
  methods: {
    changeCost(item) {
      this.editId = item.id;
      if (item.unmapped !== null) {
        this.currentEdit = item.unmapped !== -1 ? item.unmapped : "";
      }
    },
    cancelChanging() {
      this.currentEdit = "";
      this.editId = null;
    },
    async loadCost(item) {
      const newCost = this.currentEdit;
      if (this.$store.state.connections.marketplace.title === "Wildberries") {
        await this.$store.dispatch("connections/updateWbProducts", {
          item,
          newCost
        });
      }
      if (this.$store.state.connections.marketplace.title === "OZON") {
        await this.$store.dispatch("connections/updateOzProducts", {
          item,
          newCost
        });
      }
      this.currentEdit = "";
      this.editId = null;
      this.refresh();
      this.withoutCostLoader();
    },
    refresh() {
      this.$refs.table.refresh();
    },
    async tableLoader({
      query
    }) {
      return await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbProducts", "connections/getOzProducts", {}, {});
    }
  },
  computed: {
    columns() {
      // const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2)
      return this.$store.state.connections.marketplace.title === "Wildberries" ? [{
        title: "SKU",
        show: true,
        name: "nmid",
        type: "text",
        width: 110,
        filter: "text",
        noOverflow: true
      }, {
        title: "产品识别代码",
        show: true,
        name: "sku",
        type: "text",
        width: 130,
        noOverflow: true,
        filter: "text"
      }, {
        title: "品牌",
        show: true,
        name: "brand",
        type: "text",
        width: 160,
        filter: "text"
      }, {
        title: "商店名称",
        show: true,
        name: "subject",
        type: "text",
        width: 220,
        filter: "text",
        getImage: item => item.image
      }, {
        title: `类别`,
        show: true,
        name: "category",
        type: "text",
        width: 180,
        filter: "text"
      }, {
        title: `价格`,
        show: true,
        name: "price",
        type: "money",
        width: 120,
        filter: "numberRange"
      }, {
        title: `成本价`,
        show: true,
        name: "unmapped",
        type: "slot",
        width: 160,
        filter: "numberRange"
      }, {
        title: `条码`,
        show: true,
        name: "barcode",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: `添加日期`,
        show: true,
        name: "date_add",
        type: "date",
        filter: "dateRange",
        width: 160
      }, {
        title: `更新日期`,
        show: true,
        name: "date_alt",
        type: "text",
        filter: "dateRange",
        width: 160
      }]

      // OZON
      : [{
        title: "SKU",
        show: true,
        name: "fbo_sku",
        type: "text",
        width: 110,
        filter: "text",
        noOverflow: true
      }, {
        title: "产品识别代码",
        show: true,
        name: "offer_id",
        type: "text",
        width: 130,
        noOverflow: true,
        filter: "text"
      }, {
        title: "商店名称",
        show: true,
        name: "name",
        type: "text",
        width: 500,
        filter: "text",
        getImage: item => item.image
      }, {
        title: `类别`,
        show: true,
        name: "category_name",
        type: "text",
        width: 180,
        filter: "text"
      }, {
        title: `标记. 价格`,
        show: true,
        name: "marketing_price",
        type: "money",
        width: 120,
        filter: "numberRange"
      }, {
        title: `旧价格`,
        show: true,
        name: "old_price",
        type: "money",
        width: 120,
        filter: "numberRange"
      }, {
        title: `价格`,
        show: true,
        name: "price",
        type: "money",
        width: 120,
        filter: "numberRange"
      }, {
        title: `成本价`,
        show: true,
        name: "unmapped",
        type: "slot",
        width: 160,
        filter: "numberRange"
      }, {
        title: `条码`,
        show: true,
        name: "barcode",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: `已创建`,
        show: true,
        name: "created_at",
        type: "text",
        filter: "dateRange",
        width: 160
      }, {
        title: `更新`,
        show: true,
        name: "status_state_updated_at",
        type: "text",
        filter: "dateRange",
        width: 160
      }];
    }
  }
};