export default {
    data() {
        return {
            without_cost_price_total: 0,
        };
    },
    created() {
        this.loadProductsWithoutCost();
    },
    methods: {
        async loadProductsWithoutCost() {
            let products = null;
            if (this.marketplace.title === "Wildberries") {
                products = await this.$store.dispatch(
                    "connections/getWbProducts",
                    {
                        take: 1,
                        skip: 0,
                        ...this.reportSettings,
                    }
                );
            }
            if (this.marketplace.title === "OZON") {
                products = await this.$store.dispatch(
                    "connections/getOzProducts",
                    {
                        take: 1,
                        skip: 0,
                        ...this.reportSettings,
                    }
                );
            }
            console.log(products);
            this.without_cost_price_total =
                products?.totals.without_cost_price_total;
        },
    },
    watch: {
        selectedTokens() {
            this.loadProductsWithoutCost();
        },
    },
};
