//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    async tableLoader({
      query
    }) {
      return await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbSales", "connections/getOzSales", {}, {
        types: ["delivered"]
      });
    }
  },
  computed: {
    columns() {
      // const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2)
      return this.$store.state.connections.marketplace.title === "Wildberries" ? [{
        title: "SKU",
        show: true,
        name: "nmId",
        type: "text",
        width: 110,
        filter: "text",
        noOverflow: true
      }, {
        title: "订单号",
        show: true,
        name: "gNumber",
        type: "number",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "产品识别代码",
        show: true,
        name: "supplierArticle",
        type: "text",
        width: 130,
        noOverflow: true,
        filter: "text"
      }, {
        title: "品牌",
        show: true,
        name: "brand",
        type: "text",
        width: 160,
        filter: "text"
      }, {
        title: "商店名称",
        show: true,
        name: "subject",
        type: "text",
        width: 220,
        filter: "text",
        getImage: item => item.image
      }, {
        title: `尺寸`,
        show: true,
        name: "techSize",
        type: "text",
        width: 100,
        filter: "text"
      }, {
        title: `类别`,
        show: true,
        name: "category",
        type: "text",
        width: 120,
        filter: "text"
      }, {
        title: `数量`,
        show: true,
        name: "quantity",
        type: "number",
        width: 90,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `价格`,
        show: true,
        name: "totalPrice",
        type: "money",
        width: 120,
        filter: "numberRange"
      }, {
        title: `折扣`,
        show: true,
        name: "discountPercent",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: `订单实际价格`,
        show: true,
        name: "finishedPrice",
        type: "money",
        width: 200,
        filter: "numberRange"
      }, {
        title: `折扣价`,
        show: true,
        name: "priceWithDisc",
        type: "money",
        width: 260,
        filter: "numberRange"
      }, {
        title: `转移给供应商`,
        show: true,
        name: "forPay",
        type: "money",
        width: 200,
        filter: "numberRange"
      },
      // {
      //     title: `订单号`,
      //     show: true,
      //     name: "orderId",
      //     type: "text",
      //     width: 160,
      //     filter: "text",
      // },
      // {
      //     title: `世界银行号码`,
      //     show: true,
      //     name: "docNumber",
      //     type: "text",
      //     width: 160,
      //     filter: "text",
      // },
      {
        title: `订购日期`,
        show: true,
        name: "docDate",
        type: "date",
        width: 120
      }, {
        title: `更新日期`,
        show: true,
        name: "lastChangeDate",
        type: "text",
        width: 160,
        filter: "dateRange"
      }, {
        title: `条码`,
        show: true,
        name: "barcode",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: `发货仓库`,
        show: true,
        name: "warehouseName",
        type: "text",
        width: 150,
        filter: "text"
      }, {
        title: `一个国家`,
        show: true,
        name: "countryName",
        type: "text",
        width: 150,
        filter: "text"
      }, {
        title: `区`,
        show: true,
        name: "oblastOkrugName",
        type: "text",
        width: 250,
        filter: "text"
      }, {
        title: `地区`,
        show: true,
        name: "regionName",
        type: "text",
        width: 250,
        filter: "text"
      }, {
        title: `发货数量`,
        show: true,
        name: "incomeID",
        type: "number",
        width: 160,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `销售编号`,
        show: true,
        name: "saleID",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      },
      // {
      //     title: `贴纸`,
      //     show: true,
      //     name: "sticker",
      //     type: "text",
      //     width: 120,
      //     filter: "text",
      // },
      {
        title: `添加日期`,
        show: true,
        name: "date_add",
        type: "date",
        width: 160,
        filter: "dateRange"
      }] : [{
        title: "SKU",
        show: true,
        name: "sku",
        type: "text",
        width: 110,
        filter: "text",
        noOverflow: true
      }, {
        title: "产品识别代码",
        show: true,
        name: "offer_id",
        type: "text",
        width: 140,
        noOverflow: true,
        filter: "text"
      }, {
        title: "订单号",
        show: true,
        name: "order_id",
        type: "number",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "条码",
        show: true,
        name: "posting_number",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "商店名称",
        show: true,
        name: "name",
        type: "text",
        width: 500,
        filter: "text",
        getImage: item => item.image
      }, {
        title: "数量",
        show: true,
        name: "quantity",
        type: "number",
        width: 100,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "价格",
        show: true,
        name: "price",
        type: "number",
        width: 100,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "旧价格",
        show: true,
        name: "old_price",
        type: "number",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "佣金",
        show: true,
        name: "commission_amount",
        type: "money",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "佣金 %",
        show: true,
        name: "commission_percent",
        type: "number",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "支付",
        show: true,
        name: "payout",
        type: "money",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "订购日期",
        show: true,
        name: "created_at",
        type: "text",
        width: 200,
        filter: "dateRange",
        noOverflow: true
      }, {
        title: "仓库",
        show: true,
        name: "warehouse_name",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "交货类型",
        show: true,
        name: "delivery_type",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: "地区",
        show: true,
        name: "region",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "城市",
        show: true,
        name: "city",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "付款方式",
        show: true,
        name: "payment_type_group_name",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "商店状态",
        show: true,
        name: "status",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "添加日期",
        show: true,
        name: "date_add",
        type: "text",
        width: 200,
        filter: "dateRange",
        noOverflow: true
      }];
    }
  }
};